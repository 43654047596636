@import 'Colours';

.errorPageContainer {
  margin-bottom: 28px;
}

.errorTitle {
  font-size: 1.6rem;
  font-weight: 400;
  white-space: pre-line;

  b {
    font-weight: 500;
  }
}

.errorCard {
  text-align: center;
  & > p {
    margin-bottom: 16px;
  }
  
  & > svg {
    color: $paysimply-red;
    margin-bottom: 14px;
  }
}